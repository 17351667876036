import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { getSubjectConfig } from 'my-core/course-utils';
import { landingPageCTAClicked } from 'my-core/gtm-events';
import { useDialogStateManager, useStandardApiListRequest } from 'my-core/hooks';
import { getUserFirstName } from 'my-core/user-utils';

import HorizontalStepIndicator from 'my-components/HorizontalStepIndicator';
import ModalVideo from 'my-components/ModalVideo';
import ProcessedImg from 'my-elements/ProcessedImg';
import Rating from 'my-elements/Rating';
import { Container } from 'my-layout';

import ButtonBase from '@mui/material/ButtonBase';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchEducatorsApi } from 'my-actions/UserActions';

const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, transitions }) => ({
    root: { padding: spacing(10, 0) },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    videoCarousel: {
      flexShrink: 0,
      width: '55%',
      marginRight: spacing(8),
    },
    imageWrapper: {
      position: 'relative',
      width: '100%',
      paddingBottom: '65%',
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
      '& > img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
      },
    },
    playButton: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '6em',
      color: palette.common.white,
      opacity: 0.8,
      transition: transitions.create('opacity', { duration: 300 }),
      '$imageWrapper:hover &': { opacity: 1 },
    },
    instructorCard: {
      width: '90%',
      position: 'absolute',
      bottom: 0,
      left: '5%',
      padding: spacing(1, 2),
      borderRadius: [[shape.borderRadiusSm, shape.borderRadiusSm, 0, 0]],
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
    },
    ...palette.mapColors(color => ({
      [`instructorCard_${color}`]: {
        backgroundColor: palette[color].main,
        color: palette[color].contrastText,
      },
    })),
    instructorCardLeft: {
      minWidth: 0,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      '& > div': { minWidth: 0 },
    },
    subjectIcon: {
      width: 50,
      height: 50,
      marginRight: spacing(2),
    },
    instructorCardStats: {
      textAlign: 'right',
    },

    text: {
      '& > * + *': {
        marginTop: spacing(2),
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      margin: spacing(3, 0),
    },
    listItemIcon: {
      color: palette.primary.main,
      marginRight: spacing(2),
    },
    stepIndicator: {
      marginTop: spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    [breakpoints.down('md')]: {
      root: { padding: spacing(4, 0) },
      container: { flexDirection: 'column-reverse' },
      videoCarousel: {
        width: '100%',
        margin: spacing(0),
      },
      playButton: {
        top: '40%',
        fontSize: '4em',
      },
      subjectIcon: {
        width: 40,
        height: 40,
      },
    },
  }),
  { name: 'HomePageInstructors' },
);

function HomePageInstructors(props) {
  const classes = useStyles(props);

  const [ref, inView] = useInView({ rootMargin: '-250px 0px' });

  const { results: instructors } = useStandardApiListRequest({
    actionCreator: fetchEducatorsApi,
    disabled: !inView,
    limit: 10,
    params: {
      filter: { roles: ['instructor'], public_profile: true },
      fields: {
        user: ['avatar', 'degree', 'instructor_stats', 'intro_video', 'main_subject', 'name', 'slug'],
      },
      order_by: 'instructor_feedbacks',
      order: 'desc',
    },
    parentRequestKey: 'landing_page_instructors',
    storeKey: 'users',
  });

  const [activeIdx, setActiveIdx] = useState(0);
  const [videoDialogOpen, videoDialogOpened, videoDialogActions] = useDialogStateManager();

  const instructor = instructors[activeIdx];
  const instructorSubjectConfig = getSubjectConfig(instructor?.main_subject?.toLowerCase());

  const hasVideo = !!instructor?.intro_video;
  const Component = hasVideo ? ButtonBase : Paper;
  const componentProps =
    hasVideo ?
      {
        component: Paper,
        onClick: () => {
          landingPageCTAClicked('instructors', 'play_video', instructor.id);
          videoDialogActions.open(true);
        },
      }
    : {};

  return (
    <>
      <div className={classes.root} ref={ref}>
        <Container className={classes.container}>
          <div className={classes.videoCarousel}>
            <Component className={classes.imageWrapper} {...componentProps}>
              <ProcessedImg
                alt={`${getUserFirstName(instructor)} avatar`}
                options={{ width: 800 }}
                src={instructor?.avatar?.original}
              />
              {hasVideo && (
                <div className={classes.playButton}>
                  <FontAwesomeIcon icon={faPlayCircle} />
                </div>
              )}
              <div
                className={classNames(
                  classes.instructorCard,
                  classes[`instructorCard_${instructorSubjectConfig.color}`],
                )}
              >
                <div className={classes.instructorCardLeft}>
                  <img
                    alt={`${instructorSubjectConfig.name} subject icon`}
                    className={classes.subjectIcon}
                    src={instructorSubjectConfig.icon}
                  />
                  <div>
                    <Typography noWrap variant="h6">
                      {getUserFirstName(instructor)}
                    </Typography>
                    <Typography noWrap variant="body2">
                      {instructor?.main_subject || instructor?.degree || 'Wizeprep Prof'}
                    </Typography>
                  </div>
                </div>

                <div className={classes.instructorCardStats}>
                  <Rating readOnly size="small" value={5} />
                  <Typography variant="body2">
                    {instructor?.instructor_stats?.students?.total?.toLocaleString() || 0}{' '}
                    <Hidden only="xs">students</Hidden> helped
                  </Typography>
                </div>
              </div>
            </Component>
            <HorizontalStepIndicator
              activeItemIndex={activeIdx}
              className={classes.stepIndicator}
              onChangeActiveItemIndex={(idx, btn) => {
                landingPageCTAClicked('instructors', 'stepper', btn === 'dot' ? `dot_${idx + 1}` : btn);
                setActiveIdx(idx);
              }}
              stepCount={instructors.length}
            />
          </div>

          <div className={classes.text}>
            <Typography component="h2" sx={{ typography: { xs: 'h5', sm: 'h3' } }}>
              Expert Instructors Make a Difference
            </Typography>
            <Typography>
              Access professional instructors on demand for a fraction of the price of an in-person tutor.
            </Typography>
            {listItems.map((item, idx) => (
              <div key={idx} className={classes.listItem}>
                <FontAwesomeIcon className={classes.listItemIcon} icon={faCheck} size="lg" />
                <Typography variant="subtitle1">{item}</Typography>
              </div>
            ))}
          </div>
        </Container>
      </div>
      {videoDialogOpened && (
        <ModalVideo
          onClose={videoDialogActions.close}
          open={videoDialogOpen}
          PlayerProps={{
            urls: instructor.intro_video,
            trackingProps: {
              video_id: 'instructor_intro',
              instructor_id: instructor.id,
              instructor_name: instructor.name,
            },
          }}
        />
      )}
    </>
  );
}

const listItems = [
  'Highly accredited professional instructors',
  'No nonsense, no jargon, and no intimidation',
  'Real people ready to help',
];

HomePageInstructors.propTypes = {};
export default HomePageInstructors;
