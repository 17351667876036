import memoize from 'memoize-one';
import queryString from 'query-string';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function useSearchParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const locAndNavRef = useRef([location, navigate]);
  // locAndNavRef.current = [location, navigate];
  // docs recommend using useEffect, for reasons explained here:
  // https://github.com/facebook/react/issues/16956#issuecomment-543068780
  useEffect(() => {
    locAndNavRef.current = [location, navigate];
  }, [location, navigate]);

  // TODO: change 'update', 'replace' args into an 'options'
  const setSearch = useCallback((searchParams, update = false, push = false) => {
    const [location, navigate] = locAndNavRef.current;
    const search = update ? { ...parseSearch(location.search), ...searchParams } : searchParams;
    const searchStr = queryString.stringify(search, { arrayFormat: 'bracket' });
    if (searchStr !== location.search) {
      navigate({ ...location, search: searchStr }, { replace: !push });
    }
  }, []);

  const searchParams = parseSearch(location.search);

  return [searchParams, setSearch];
}

const parseSearch = memoize(search => queryString.parse(search, { arrayFormat: 'bracket' }));
