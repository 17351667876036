import { makeStyles } from '@mui/styles';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { useDebounce, useStandardApiListRequest } from 'my-core/hooks';

import ItemList from 'my-components/ItemList';
import { Container } from 'my-layout';

import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ breakpoints, palette, shape: { borderRadius }, spacing }) => ({
    root: {}, // for styling the background
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: spacing(8),
      paddingBottom: spacing(8),
    },
    searchInputBase: {
      width: '100%',
      maxWidth: 746,
      height: 56,
      paddingLeft: 14,
      paddingRight: 14,
      margin: spacing(3, 0),
      border: [[1, 'solid', palette.divider]],
      backgroundColor: palette.background.paper,
      borderRadius,
    },
    searchInput: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    searchIcon: { color: palette.text.secondary },

    list: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: spacing(3),
      marginBottom: spacing(4),
    },

    [breakpoints.up('sm')]: {
      container: { paddingTop: spacing(16), paddingBottom: spacing(16) },
      searchInputBase: { margin: spacing(5, 0) },
      list: { gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))' },
    },
  }),
  { name: 'LandingPageItemList' },
);

const LandingPageItemList = forwardRef(function LandingPageItemList(props, ref) {
  const {
    defaultFilter,
    empty,
    emptyFilter,
    fetchAction,
    fetchKey: fetchKeyProp,
    fetchParams,
    ItemComponent,
    itemComponentProps,
    itemEntity,
    onSeeMoreClicked,
    searchPlaceholder,
    storeKey,
    title,
  } = props;
  const classes = useStyles(props);

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 250);

  const filter = {
    ...defaultFilter,
    ...(debouncedQuery ? { query: debouncedQuery } : emptyFilter),
  };
  const fetchKey = `${fetchKeyProp}_${JSON.stringify(filter)}`;

  const { fetching, fetchMoreResults, parentRequestStatus, requestStatus, results, total } = useStandardApiListRequest({
    actionCreator: fetchAction,
    parentRequestKey: fetchKey,
    storeKey,
    params: {
      ...fetchParams,
      ...(debouncedQuery ? { order: undefined, order_by: undefined } : null),
      filter: filter,
    },
    limit: 8,
  });
  const prevResults = useRef();
  useEffect(() => {
    prevResults.current = requestStatus?.success ? results : prevResults.current;
  }, [requestStatus?.success, results]);
  const [listKey, setListKey] = useState(fetchKey);
  useEffect(() => {
    if (parentRequestStatus?.requests?.[0]?.success) setListKey(fetchKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRequestStatus?.requests?.[0]?.success]);

  const skeletonItems = [...Array(6)].map((_, idx) => ({ id: idx }));

  return (
    <div className={classes.root} ref={ref}>
      <Container className={classes.container}>
        <Typography component="h2" sx={{ typography: { xs: 'h5', sm: 'h3' } }}>
          {title}
        </Typography>
        <InputBase
          className={classes.searchInputBase}
          endAdornment={
            (fetching && debouncedQuery) || query !== debouncedQuery ?
              <InputAdornment position="end">
                <CircularProgress size={18} />
              </InputAdornment>
            : undefined
          }
          inputProps={{ className: classes.searchInput, 'data-hj-allow': '' }}
          onChange={e => setQuery(e.target.value)}
          placeholder={searchPlaceholder}
          startAdornment={
            <InputAdornment position="start">
              <FontAwesomeIcon className={classes.searchIcon} icon={faSearch} />
            </InputAdornment>
          }
          value={query}
        />
        <ItemList
          key={listKey}
          className={classes.list}
          component={ItemComponent}
          componentProps={{ loading: fetching && !prevResults.current?.length, ...itemComponentProps }}
          defaultRows={2}
          entity={itemEntity}
          fetchError={requestStatus?.fetchError}
          fetching={!!(fetching && results.length)}
          fetchMore={fetchMoreResults}
          idxForKey
          items={fetching ? prevResults.current || skeletonItems : results}
          onSeeMoreClicked={onSeeMoreClicked}
          total={total}
        />
        {query && !fetching && !results.length && empty}
      </Container>
    </div>
  );
});

LandingPageItemList.propTypes = {};
export default LandingPageItemList;
