import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router';

import { SUBJECT_CONFIG } from 'my-core/course-utils';
import {
  homepageSearchCourseSearched,
  homepageSearchCourseSelected,
  homepageSearchSchoolSearched,
  homepageSearchSchoolSelected,
  landingPageCTAClicked,
} from 'my-core/gtm-events';
import { coursePath, coursesPath } from 'my-core/routes';
import { getSessionSchool, setSessionSchool } from 'my-core/session-utils';
import { Hotjar } from 'my-core/tag-manager';

import SearchField from 'my-components/SearchField';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';

const PREFIX = 'hpcs';
const classes = {
  searchField: `${PREFIX}-search-field`,
  searchInput: `${PREFIX}-search-input`,
  searchCaret: `${PREFIX}-search-caret`,
  searchBtn: `${PREFIX}-search-btn`,
  coursesWrapper: `${PREFIX}-courses-wrapper`,
  courseCard: `${PREFIX}-course-card`,
  courseCardTop: `${PREFIX}-course-card-top`,
  courseCardSchool: `${PREFIX}-course-card-school`,
  courseCardName: `${PREFIX}-course-card-name`,
};
const Root = styled('div')(({ theme: { breakpoints, palette, shadows, spacing } }) => ({
  width: 900,
  maxWidth: '100%',
  display: 'flex',
  boxShadow: shadows[9],
  borderRadius: 28,

  [`& .${classes.searchField}`]: {
    flex: 1,
    '&:first-of-type': {
      [`& .${classes.searchInput}`]: {
        borderTopLeftRadius: 28,
        borderBottomLeftRadius: 28,
      },
    },
  },
  [`& .${classes.searchInput}`]: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    paddingRight: 7,
    borderRadius: 0,

    '& svg': { color: 'rgba(0, 0, 0, 0.54)' }, // palette.action.active
    '& fieldset': { borderColor: 'rgba(0, 0, 0, 0.23)' }, // palette.form.input
    [`&.Mui-focused .${classes.searchCaret} svg`]: { transform: 'rotate(180deg)' },
  },
  [`& .${classes.searchBtn}`]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 28,
    borderBottomRightRadius: 28,
  },

  [breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: 0,

    '& > *': {
      margin: spacing(0, 0, 1),
      width: '100%',
    },

    [`& .${classes.searchInput}`]: {
      borderRadius: 28,
      height: 44,
      backgroundColor: 'transparent',
      color: palette.common.white,
      '& svg': { color: palette.text.secondary },
      '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.6)' },
      '&:hover fieldset': { borderColor: palette.common.white },
    },
    [`& .${classes.searchBtn}`]: {
      width: '100%',
      height: 44,
      alignSelf: 'flex-end',
      borderRadius: 28,
    },
  },
}));

let hjEventSent = false;

export default function HomePageHeroSearch() {
  const navigate = useNavigate();
  const [schoolQuery, setSchoolQuery] = useState('');
  const [courseQuery, setCourseQuery] = useState('');
  const [school, setSchool] = useState(getSessionSchool);
  const schoolSearchEl = useRef();
  const courseSearchEl = useRef();
  const timeoutRef = useRef({});
  useEffect(() => {
    clearTimeout(timeoutRef.current.school);
    if (schoolQuery) timeoutRef.current.school = setTimeout(() => homepageSearchSchoolSearched(schoolQuery), 1000);
  }, [schoolQuery]);
  const subjectSelect = !school || !school.id;
  useEffect(() => {
    clearTimeout(timeoutRef.current.course);
    if (courseQuery)
      timeoutRef.current.course = setTimeout(
        () =>
          homepageSearchCourseSearched(
            courseQuery,
            subjectSelect ? 'subject' : 'course',
            school?.id || school?.type,
            school?.name || school?.primaryText,
            school?.type,
          ),
        1000,
      );
  }, [courseQuery, school, subjectSelect]);

  const initialMountRef = useRef(true);
  useEffect(() => {
    if (school && !initialMountRef.current) {
      // without the timeout, the course SearchField's `handleWindowClick` handler will attached and called as part of the click that caused this effect, resulting in it instantly closing
      // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
      setTimeout(() => courseSearchEl.current.focus(), 0);
    }
    initialMountRef.current = false;
  }, [school]);

  const hasCourseQuery = Boolean(courseQuery);
  useEffect(() => {
    // Even though we want to focus on the course search element anytime the
    // query changes, we wait until the course query exists because we don't
    // want this to trigger when the component initially renders.
    if (hasCourseQuery) {
      const focusCourseSearch = () => courseSearchEl.current?.focus();
      focusCourseSearch();
      return focusCourseSearch;
    }
  }, [hasCourseQuery]);

  const coursesPathParams = {
    school_type: school?.type || 'undergrad',
    ctry: school?.country_code,
    reg: school?.region_code,
    ...((!school || school.type === 'undergrad') && { sid: school?.id || null, sname: school?.name }),
  };

  return (
    <Root
      onClick={() => {
        if (hjEventSent) return;
        hjEventSent = true;
        Hotjar('event', 'homepage_search_interacted');
      }}
    >
      <SearchField
        className={classes.searchField}
        createNewItem={(query, results) => {
          if (results.length) return;
          return [
            {
              resultDisabled: true,
              primaryText: 'School not found',
              primaryTextProps: { color: 'secondary' },
              secondaryText: 'Check spelling or continue with high school or college',
              _type: 'custom',
            },
            {
              primaryText: 'Undergraduate courses',
              type: 'undergrad',
            },
            {
              primaryText: 'High school courses',
              type: 'high_school',
            },
          ];
        }}
        defaultValue={school?.name || school?.primaryText}
        inputProps={{
          fullWidth: true,
          inputProps: { ref: schoolSearchEl, 'data-hj-allow': '' },
          InputProps: {
            className: classes.searchInput,
            endAdornment: (
              <InputAdornment className={classes.searchCaret} position="end">
                <IconButton edge="end" onClick={() => schoolSearchEl.current?.focus()} size="small">
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          },
          placeholder: 'Your school',
        }}
        onChange={setSchoolQuery}
        onClear={
          schoolQuery || school ?
            () => {
              setSessionSchool(null);
              setSchool();
              setSchoolQuery('');
            }
          : undefined
        }
        onResultClick={s => {
          homepageSearchSchoolSelected(s.id || s.type, s.name || s.primaryText, s.type);
          setSessionSchool(s);
          setSchool(s);
        }}
        PopperProps={{ sx: { '& .MuiPaper-root': { boxShadow: 23 } } }}
        resultToQuery={s => s.name || s.primaryText}
        searchOnEmpty
        searchParams={{
          limit: 10,
          ...(!schoolQuery && { order_by: 'loc_and_popularity', school_type: 'undergrad' }),
        }}
        type="schools"
      />
      {!courseQuery ?
        <Autocomplete
          className={classes.searchField}
          getOptionLabel={s => s.name}
          onChange={(e, o) => {
            homepageSearchCourseSelected('subject', school?.type, o.key);
            navigate(coursesPath({ ...coursesPathParams, subject: o.key }));
          }}
          onInputChange={(e, value, reason) => reason === 'input' && setCourseQuery(value)}
          openOnFocus
          options={SUBJECT_OPTIONS}
          renderInput={props => (
            <TextField
              {...props}
              InputProps={{ ...props.InputProps, className: classes.searchInput }}
              inputProps={{
                'data-hj-allow': '',
                ...props.inputProps,
                ref: e => {
                  props.inputProps.ref.current = e;
                  courseSearchEl.current = e;
                },
              }}
              placeholder="Course code"
            />
          )}
          renderOption={({ key, ...props }, subject) => (
            <ListItem key={key} {...props}>
              <ListItemAvatar>
                <img height={32} src={subject.icon} width={32} />
              </ListItemAvatar>
              <ListItemText primary={`All ${subject.name}`} />
            </ListItem>
          )}
          slotProps={{ paper: { sx: { boxShadow: 23 } } }}
          value={null}
        />
      : <SearchField
          className={classes.searchField}
          createdItemPlacement="start"
          createNewItem={query => {
            query = query.toLowerCase();
            return SUBJECT_OPTIONS.filter(({ codes }) => codes.some(code => query.startsWith(code))).map(subject => ({
              code: `All ${subject.name}`,
              subject: subject.key,
              _type: 'course',
              _isSubjectResult: true,
            }));
          }}
          inputProps={{
            fullWidth: true,
            inputProps: { ref: courseSearchEl, 'data-hj-allow': '' },
            InputProps: {
              className: classes.searchInput,
              endAdornment: (
                <InputAdornment className={classes.searchCaret} position="end">
                  <IconButton edge="end" onClick={() => courseSearchEl.current?.focus()} size="small">
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
            placeholder: 'Course code',
            value: courseQuery,
          }}
          onChange={setCourseQuery}
          onResultClick={c => {
            if (c._isSubjectResult) {
              homepageSearchCourseSelected('subject', school?.type, c.subject);
              navigate(coursesPath({ ...coursesPathParams, subject: c.subject }));
            } else {
              homepageSearchCourseSelected('course', school?.type, c.code, c.id);
              navigate(coursePath(c));
            }
          }}
          PopperProps={{ sx: { '& .MuiPaper-root': { boxShadow: 23 } } }}
          resultProps={{ withAvatar: true }}
          searchOnEmpty
          searchParams={{
            limit: 10,
            ...(school && {
              school_with_ancestors_id: school.id,
              prioritize_school_id: school.id,
              school_type: school.type,
            }),
          }}
          type="courses"
        />
      }
      <Button
        className={classes.searchBtn}
        component={Link}
        onClick={() => landingPageCTAClicked('homepage_search', 'find_your_course')}
        to={coursesPath(coursesPathParams)}
        variant="contained"
      >
        Find Your Course
      </Button>
    </Root>
  );
}

const SUBJECT_OPTIONS = Object.values(SUBJECT_CONFIG).filter(
  ({ key }) => !['cars', 'logic', 'reading', 'reasoning', 'wize'].includes(key),
);
