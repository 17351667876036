import { blurbs, testimonialsHomepageBlurbs } from 'my-core/landing-data';

import Container from 'my-layout/Container';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classes = {
  root: ({ breakpoints, palette, spacing }) => ({
    backgroundColor: palette.background.paper,
    padding: spacing(5, 0),

    [breakpoints.up('sm')]: {
      padding: spacing(9, 0),
    },
  }),
  container: ({ breakpoints, spacing }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),

    [breakpoints.up('sm')]: {
      gap: spacing(8),
    },
  }),

  item: ({ breakpoints, spacing }) => ({
    display: 'flex',
    gap: spacing(2),

    [breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      gap: spacing(4),

      '&:nth-of-type(even)': { flexDirection: 'row-reverse' },
    },
    [breakpoints.up('md')]: {
      gap: spacing(8),
    },
  }),

  blurb: ({ breakpoints }) => ({
    [breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  }),
  blurbImage: ({ breakpoints }) => ({
    width: 148,
    height: 148,

    [breakpoints.up('sm')]: {
      width: 140,
      height: 140,
    },
  }),
  blurbHeader: ({ spacing }) => ({
    marginTop: spacing(2),
    marginBottom: spacing(1),
  }),

  testimonial: ({ breakpoints, spacing }) => ({
    padding: spacing(3),
    borderRadius: 16,

    [breakpoints.up('sm')]: {
      flexShrink: 0,
      width: '49.5%',
    },
    [breakpoints.up('md')]: {
      padding: spacing(4),
    },
  }),
  testimonialIcon: ({ breakpoints }) => ({
    lineHeight: 0,
    fontSize: 24,

    [breakpoints.up('sm')]: {
      fontSize: 33,
    },
  }),
  testimonialContent: ({ breakpoints, spacing, typography }) => ({
    margin: spacing(2, 0),

    [breakpoints.up('sm')]: {
      fontSize: typography.subtitle1.fontSize,
      fontWeight: typography.fontWeightExtraBold,
      fontStyle: 'italic',
    },
    [breakpoints.up('md')]: {
      fontSize: typography.h6.fontSize,
    },
  }),
  testimonialUser: ({ breakpoints, spacing }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),

    [breakpoints.up('sm')]: {
      gap: spacing(1.5),
    },
  }),
  testimonialAvatar: ({ breakpoints }) => ({
    width: 34,
    height: 34,

    [breakpoints.up('sm')]: {
      width: 60,
      height: 60,
    },
    [breakpoints.up('md')]: {
      width: 80,
      height: 80,
    },
  }),
};

export default function HomePageBlurbs() {
  const isBig = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));

  function renderBlurb(blurb) {
    const blurbHeaders =
      isBig ?
        { header: blurb.headerLong, subheader: blurb.subheaderLong }
      : { header: blurb.header, subheader: blurb.subheader };

    return (
      <div key={blurb.header} css={classes.blurb}>
        <img alt={blurb.header} css={classes.blurbImage} src={blurb.source} />
        <Typography component="h3" css={classes.blurbHeader} sx={{ typography: { xs: 'subtitle1', sm: 'h5' } }}>
          {blurbHeaders.header}
        </Typography>
        <Typography sx={{ typography: { xs: 'body2', sm: 'body1' } }}>{blurbHeaders.subheader}</Typography>
      </div>
    );
  }

  function renderTestimonial(testimonial) {
    return (
      <Box
        key={testimonial.author}
        component="article"
        css={classes.testimonial}
        sx={{ bgcolor: `${testimonial.color}.background` }}
      >
        <Box css={classes.testimonialIcon} sx={{ color: `${testimonial.color}.main` }}>
          <FontAwesomeIcon icon={faQuoteLeft} />
        </Box>
        <Typography css={classes.testimonialContent} variant="body2">
          {testimonial.testimonial}
        </Typography>
        <div css={classes.testimonialUser}>
          <Avatar
            alt={`${testimonial.author} profile picture`}
            css={classes.testimonialAvatar}
            src={testimonial.imageSrc}
          />
          <Typography sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1', md: 'h6' } }}>
            {testimonial.author}, {testimonial.gradeLevel}
          </Typography>
        </div>
      </Box>
    );
  }

  return (
    <div css={classes.root}>
      <Container css={classes.container}>
        <Typography align="center" component="h2" sx={{ typography: { xs: 'h5', sm: 'h3' } }}>
          Experience the Wizeprep Advantage
        </Typography>
        {ITEMS.map(([blurb, testimonial], index) => (
          <div key={index} css={classes.item}>
            {renderBlurb(blurb)}
            {renderTestimonial(testimonial)}
          </div>
        ))}
      </Container>
    </div>
  );
}

const BLURBS = [
  {
    ...blurbs.better_grades,
    headerLong: 'Get Better Grades',
    subheaderLong:
      'Get ahead of the curve like the 98% of students who saw an increase in their grades after using Wizeprep to study.',
  },
  {
    ...blurbs.understand,
    headerLong: 'Really Understand Tough Concepts',
    subheaderLong: 'Instructors break down difficult subjects into small, simple lessons and practice problems.',
  },
  {
    ...blurbs.optimize,
    headerLong: 'Spend Less Time Studying',
    subheaderLong: 'Wizeprep’s quick, curated lessons allow you to focus your study time where it matters.',
  },
];
const TESTIMONIALS = [
  {
    ...testimonialsHomepageBlurbs[0],
    testimonial:
      'After discovering Wizeprep at the beginning of my second semester, my grades have gone up significantly. I feel so much more confident when taking my exams.',
    color: 'primary',
  },
  {
    ...testimonialsHomepageBlurbs[1],
    testimonial:
      "I was really struggling with my class in macroeconomics. I had tried every study method possible, but I still wasn't able to get a decent grade. [...] I then decided to try Wizeprep. To my surprise, the tutor really explained my Econ class in such a way that I finally understood!",
    color: 'purple',
  },
  { ...testimonialsHomepageBlurbs[2], color: 'green' },
];

const ITEMS = [
  [BLURBS[0], TESTIMONIALS[0]],
  [BLURBS[1], TESTIMONIALS[1]],
  [BLURBS[2], TESTIMONIALS[2]],
];
