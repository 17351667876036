import { Link } from 'react-router';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { mcatLandingPath } from 'my-core/routes';

import Container from 'my-layout/Container';

import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import labCoatManSrc from 'images/artwork/lab-coat-man.svg';

const classes = {
  root: ({ breakpoints, palette, spacing }) => ({
    color: palette.common.white,
    backgroundColor: palette.purple.main,

    [breakpoints.only('xs')]: {
      padding: spacing(4, 0),
      textAlign: 'center',
    },
    [breakpoints.only('sm')]: {
      paddingTop: spacing(2),
    },
  }),

  container: ({ breakpoints, spacing }) => ({
    [breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      columnGap: spacing(4),
    },
    [breakpoints.up('md')]: {
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
    },
  }),

  image: ({ breakpoints, spacing }) => ({
    width: 110,

    [breakpoints.only('sm')]: {
      gridRow: '1 / 3',
      alignSelf: 'end',
    },
    [breakpoints.up('sm')]: {
      width: 170,
    },
    [breakpoints.up('md')]: {
      paddingTop: spacing(1.5),
    },
  }),
  link: ({ breakpoints, spacing }) => ({
    flexShrink: 0,
    padding: spacing(1),

    [breakpoints.only('sm')]: {
      marginLeft: spacing(-2),
    },
    [breakpoints.up('sm')]: {
      padding: spacing(2),
      justifySelf: 'start',
    },
  }),
};

export default function HomePageMCATBand() {
  return (
    <div css={classes.root}>
      <Container css={classes.container}>
        <img alt="man in lab coat" css={classes.image} src={labCoatManSrc} />
        <div>
          <Typography variant="h5">Prepping for the MCAT?</Typography>
          <Typography sx={{ typography: { sm: 'body3' } }}>
            Wizeprep has everything you need to get you into the best medical&nbsp;school.
          </Typography>
        </div>
        <MuiLink
          color="inherit"
          component={Link}
          css={classes.link}
          onClick={() => landingPageCTAClicked('mcat_band', 'mcat')}
          sx={{ typography: { sm: 'body3' } }}
          to={mcatLandingPath()}
        >
          Learn more
        </MuiLink>
      </Container>
    </div>
  );
}
